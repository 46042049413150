import Sentry from "@utils/sentry";
import { Chart, ChartConfiguration, ChartData, ChartDataSets } from 'chart.js';
import { Controller } from "stimulus";
import { i18n } from "../i18n/config";
import Spinner from "../util/spinner";

export default class extends Controller {
    static targets = ["chartCanvas", "spinnerContainer"];

    element: HTMLDivElement;
    spinner: Spinner;

    chartCanvasTarget: HTMLCanvasElement;
    spinnerContainerTarget: HTMLDivElement;

    private readonly COLORS = {
        blue: '#0091EB',
        lightGray: '#DADADA',
        darkGray: '#7C7C7C',
        lightBlue: '#61dafb',
        lightGreen: '#90ee90',
        darkGreen: '#006400'
    };

    connect() {
        this.spinner = new Spinner(this.spinnerContainerTarget);
        this.spinner.start();
        fetch(this.data.get("url"), { credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                this.spinner.stop();
                const chartType = this.data.get("chartType");
                switch (chartType) {
                    case "lineChart":
                        this.showDailyBarChart(
                            data.x_labels,
                            [
                                data.ios,
                                data.android,
                                data.flutter,
                                data.i18next,
                            ]
                        );
                        break;
                    case "requestsPerLocale":
                        this.showBarChart(
                            data.locales_graph.x,
                            data.locales_graph.y
                        );
                        break;
                    case "requestsPerPlatform":
                        this.showBarChart(data.x, data.y, 0.5);
                        break;
                    default:
                        console.error("Chart type not supported");
                }
            })
            .catch(error => {
                Sentry.notify(error);
                this.spinner.stop();
                const container = this.chartCanvasTarget.parentElement;
                const errorParagraph = document.createElement("p");
                const errorMessage = document.createTextNode(i18n.t("ota_metrics.could_not_fetch_statistics"));
                errorParagraph.appendChild(errorMessage);
                container.replaceChild(errorParagraph, this.chartCanvasTarget);
            });
    }

    showDailyBarChart(xLabels: string[], yData: any[]) {
        const datasets: ChartDataSets[] = [
            {
                label: i18n.t("ota_metrics.ios_label"),
                fill: false,
                borderColor: this.COLORS.darkGray,
                backgroundColor: this.COLORS.darkGray,
                pointBackgroundColor: this.COLORS.darkGray,
                borderCapStyle: 'butt',
                data: yData[0],
            },
            {
                label: i18n.t("ota_metrics.android_label"),
                fill: false,
                borderColor: this.COLORS.lightGray,
                backgroundColor: this.COLORS.lightGray,
                pointBackgroundColor: this.COLORS.lightGray,
                borderCapStyle: 'butt',
                data: yData[1],
            },
            {
                label: i18n.t("ota_metrics.flutter_label"),
                fill: false,
                borderColor: this.COLORS.blue,
                backgroundColor: this.COLORS.blue,
                pointBackgroundColor: this.COLORS.blue,
                borderCapStyle: 'butt',
                data: yData[2],
            },
            {
                label: i18n.t("ota_metrics.i18next_label"),
                fill: false,
                borderColor: this.COLORS.darkGreen,
                backgroundColor: this.COLORS.darkGreen,
                pointBackgroundColor: this.COLORS.darkGreen,
                borderCapStyle: 'butt',
                data: yData[3],
            },
        ];
        const chartData: ChartData = {
            labels: xLabels,
            datasets: datasets,
        };
        this.displayLineChart(chartData, this.chartCanvasTarget.getContext("2d"));
    }

    showBarChart(xLabels: string[], yData: number[], width = 0.9) {
        const ctx = this.chartCanvasTarget.getContext("2d");
        const datasets: ChartDataSets[] = [
            {
                data: yData,
                backgroundColor: this.COLORS.darkGray,
            }
        ];
        const chartData: ChartData = {
            labels: xLabels,
            datasets: datasets,
        };
        this.displayBarChart(chartData, width, ctx);
    }

    private displayLineChart(data: ChartData, ctx) {
        const config: ChartConfiguration = {
            type: 'bar',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 750,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                        stacked: true,
                    }],
                    xAxes: [{
                        type: 'time',
                        stacked: true,
                    }]
                }
            }
        };

        // Typings currently don't include this option
        config.options.scales.yAxes[0].ticks['precision'] = 0;
        const chart: Chart = new Chart(ctx, config);
        chart.update();
    }

    private displayBarChart(data: ChartData, width: number, ctx) {
        const config: ChartConfiguration = {
            type: 'bar',
            data: data,
            options: {
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 750,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                    }],
                    xAxes: [
                        {
                            barPercentage: width,
                        }
                    ]
                }
            }
        };

        // Typings currently don't include this option
        config.options.scales.yAxes[0].ticks['precision'] = 0;
        const chart: Chart = new Chart(ctx, config);
        chart.update();
    }
}
